import { Text } from '@chakra-ui/react';

export function highlightText(text: string, searchTerm: string) {
	if (!searchTerm) return text;

	const escapedSearchTerm = searchTerm.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
	const regex = new RegExp(`(${escapedSearchTerm})`, 'gi');

	return text.split(regex).map((part, index) =>
		part.toLowerCase() === searchTerm.toLowerCase() ? (
			// eslint-disable-next-line
			<Text as="mark" key={`${part}-${index}`}>
				{part}
			</Text>
		) : (
			part
		),
	);
}
