import React, { useEffect, useState } from 'react';
import { Input, InputProps } from '@chakra-ui/react';

interface DebouncedInputProps extends Omit<InputProps, 'onChange'> {
	value: string | number;
	onChange: (value: string | number) => void;
	debounce?: number;
}

function DebouncedInput({ value: initialValue, onChange, debounce = 500, ...props }: DebouncedInputProps) {
	const [value, setValue] = useState(initialValue);

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			onChange(value);
		}, debounce);

		return () => clearTimeout(timeout);
	}, [value]);

	return <Input {...props} value={value} onChange={(e) => setValue(e.target.value)} />;
}

export default DebouncedInput;
