import React from 'react';
import { useParams } from 'next/navigation';
import { useDisclosure } from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import CommandMenu from './CommandMenu';

export default function Command() {
	const { ownerId } = useParams();

	const { open, onOpen, onClose } = useDisclosure();

	return (
		<>
			{ownerId && <FiSearch size="18px" onClick={onOpen} cursor="pointer" />}
			<CommandMenu isOpen={open} ownerId={ownerId as string} onClose={onClose} />
		</>
	);
}
