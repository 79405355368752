import { forwardRef } from 'react';
import { Drawer as ChakraDrawer, Portal } from '@chakra-ui/react';
import { CloseButton } from './close-button';

interface DrawerContentProps extends ChakraDrawer.ContentProps {
	portalled?: boolean;
	portalRef?: React.RefObject<HTMLElement>;
	offset?: ChakraDrawer.ContentProps['padding'];
}

// eslint-disable-next-line react/display-name
export const DrawerContent = forwardRef<HTMLDivElement, DrawerContentProps>((props, ref) => {
	const { children, portalled = true, portalRef, offset, ...rest } = props;
	return (
		<Portal disabled={!portalled} container={portalRef}>
			<ChakraDrawer.Positioner padding={offset}>
				<ChakraDrawer.Content ref={ref} {...rest} asChild={false}>
					{children}
				</ChakraDrawer.Content>
			</ChakraDrawer.Positioner>
		</Portal>
	);
});

// eslint-disable-next-line react/display-name
export const DrawerCloseTrigger = forwardRef<HTMLButtonElement, ChakraDrawer.CloseTriggerProps>((props, ref) => (
	<ChakraDrawer.CloseTrigger position="absolute" top="2" insetEnd="2" {...props} asChild>
		<CloseButton size="sm" ref={ref} />
	</ChakraDrawer.CloseTrigger>
));

export const DrawerTrigger = ChakraDrawer.Trigger;
export const DrawerRoot = ChakraDrawer.Root;
export const DrawerFooter = ChakraDrawer.Footer;
export const DrawerHeader = ChakraDrawer.Header;
export const DrawerBody = ChakraDrawer.Body;
export const DrawerBackdrop = ChakraDrawer.Backdrop;
export const DrawerDescription = ChakraDrawer.Description;
export const DrawerTitle = ChakraDrawer.Title;
export const DrawerActionTrigger = ChakraDrawer.ActionTrigger;
