import React from 'react';

import { Button } from '@/components/ui/button';

export default function NavButton({
	text,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	icon,
	isBoldAndDark = false,
	onClick,
	isActive = false,
	hoverColor = 'neutralAccent',
}: {
	text: string;
	icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
	isBoldAndDark?: boolean;
	onClick?: () => void;
	isActive?: boolean;
	hoverColor?: string;
}) {
	const getFontWeight = () => {
		if (isBoldAndDark) {
			return '600';
		}
		if (isActive) {
			return '500';
		}
		return '400';
	};

	const getColor = () => {
		if (isBoldAndDark) {
			return 'bodyText';
		}
		if (isActive) {
			return 'primaryText';
		}
		return 'navButtonTextColor';
	};

	return (
		<Button
			p={3}
			textStyle="md"
			lineHeight="120%"
			fontWeight={getFontWeight()}
			// eslint-disable-next-line no-nested-ternary
			color={getColor()}
			bg={isActive ? 'primaryAccentAlpha' : 'transparent'}
			w="100%"
			justifyContent="flex-start"
			transition="all 0.3s easy"
			_hover={{
				bgColor: hoverColor,
			}}
			onClick={onClick}
		>
			{icon}
			{text}
		</Button>
	);
}
