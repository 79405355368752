'use client';

import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { GroupedNotification } from '@shared/zod-schemas';

import NotificationItem from './NotificationItem';

export default function Notifications({ groupedNotifications }: { groupedNotifications: GroupedNotification }) {
	const n = useTranslations('Notifications');

	return (
		<>
			{groupedNotifications?.newer?.length !== 0 && (
				<Box>
					<Text fontWeight={600} color="titleColor" mb={2} pl={3}>
						{n('newer')}
					</Text>

					<Flex flexDirection="column" gap={3}>
						{groupedNotifications.newer.map((notification, index, arr) => (
							<NotificationItem
								key={notification.id}
								notification={notification}
								isLast={index === arr.length - 1}
							/>
						))}
					</Flex>
				</Box>
			)}
			{groupedNotifications?.older?.length !== 0 && (
				<Box w="full">
					<Text fontWeight={600} color="titleColor" mb={2} pl={3}>
						{n('older')}
					</Text>

					<Flex flexDirection="column" gap={3} mb={1}>
						{groupedNotifications.older.map((notification, index, arr) => (
							<NotificationItem
								key={notification.id}
								notification={notification}
								isLast={index === arr.length - 1}
							/>
						))}
					</Flex>
				</Box>
			)}
		</>
	);
}
