import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { Search } from '@shared/zod-schemas';

import SearchResult from '@/components/command/search/SearchResult';
import NestedCategory from '@/components/command/search/NestedCategory';

interface SearchCategoryInterface {
	result: Search;
	onClose: () => void;
}

export default function SearchCategory({ result, onClose }: SearchCategoryInterface) {
	const t = useTranslations('Command');

	return (
		<Box>
			<Text fontSize="12px" fontWeight={500} color="thTextColor" mb={2}>
				{t(`${result.category}.${result.category}`)}
			</Text>
			<Flex flexDir="column" gap={1}>
				{result.results.map((r) => (
					<>
						<SearchResult key={r.id} r={r} onClose={onClose} categoryName={result.category} />

						{r.nestedSearch?.map((ns) => <NestedCategory key={ns.id} ns={ns} onClose={onClose} />)}
					</>
				))}
			</Flex>
		</Box>
	);
}
