import { Flex, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { format } from 'date-fns';
import { useQueryClient } from '@tanstack/react-query';
import { GroupedNotificationData } from '@shared/zod-schemas';
import { getGetGroupedNotificationsQueryKey, useUpdateNotificationById } from '@shared/client-api';
import { useRouter } from '@/i18n/routing';
import { errorToast, successToast } from '@/utils/toast';

import { getTextColor } from '@/utils/notification';

import { Button } from '../ui/button';

export default function NotificationContent({ notification }: { notification: GroupedNotificationData }) {
	const n = useTranslations('Notifications');
	const router = useRouter();

	const queryClient = useQueryClient();
	const { mutateAsync, isPending } = useUpdateNotificationById();

	return (
		<>
			<Flex justifyContent="space-between" gap={1}>
				<Text fontWeight={700} color={getTextColor(notification.notification?.type as string)}>
					{notification.senderName}{' '}
					<Text as="span" fontWeight={400}>
						{notification.notification?.content}
					</Text>{' '}
					{notification.notification?.infoContent}
				</Text>

				<Text fontWeight={500} color="descriptionTextColor">
					{notification.createdAt && format(new Date(notification.createdAt), 'dd.MM.yyyy')}
				</Text>
			</Flex>

			<Flex mt="8px" gap="4px">
				<Button
					colorPalette="green"
					size="sm"
					backgroundColor="bgGreen"
					variant="solid"
					color="green.400"
					_hover={{ bg: 'green.500', color: 'white' }}
					onClick={() => {
						mutateAsync(
							{
								id: notification.id,
								data: {
									isRead: true,
								},
							},
							{
								onSuccess: () => {
									successToast(n('markAsRead'));
								},
								onError: () => {
									errorToast(n('markAsRead'));
								},
							},
						).then(() => {
							queryClient.resetQueries({ queryKey: getGetGroupedNotificationsQueryKey() });
							router.refresh();
						});
					}}
					loading={isPending}
				>
					{n('markAsRead')}
				</Button>
			</Flex>
		</>
	);
}
