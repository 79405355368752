import { useMemo, useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { FiBell } from 'react-icons/fi';
import { useGetGroupedNotifications } from '@shared/client-api';
import { GroupedNotification } from '@shared/zod-schemas/';
import { createClient } from '@/supabase/client';
import { mergeNotificationsAndInvitations } from '@/utils/notification';
import { useUser } from '@/supabase/AuthContext';

import { PopoverBody, PopoverContent, PopoverRoot, PopoverTitle, PopoverTrigger } from '@/components/ui/popover';
import Notifications from './Notifications';

export function NotificationPopover() {
	const n = useTranslations('Notifications');
	const [notifications, setNotifications] = useState<GroupedNotification>();

	const user = useUser();

	useEffect(() => {
		const supabase = createClient();
		// nikde jsem moc nedohledal nejaky error stavy. nevim jeslti to treba reconnectuje samo apod.
		// musi se to vyzkouset jak se to bude chovat pripadne dodelat nejaky auto reconnect
		const changes = supabase
			.channel('table-db-changes')
			.on(
				'postgres_changes',
				{
					event: 'INSERT',
					schema: 'public',
					table: 'Notification',
				},
				(payload) => {
					// todo tohle je na refactor trochu te logiky
					// z toho realtime se nedotahne info u useru tak ho tady takhle docpu
					setNotifications((oldState) => ({
						older: oldState?.older || [],
						newer: [
							...mergeNotificationsAndInvitations([{ ...payload.new, user }], []),
							...(oldState?.newer || []),
						],
					}));
				},
			)
			.on(
				'postgres_changes',
				{
					event: 'INSERT',
					schema: 'public',
					table: 'Invitation',
				},
				(payload) => {
					// todo tady taky chybej data, potreba to refactorovat aby byli k dispozici
					setNotifications((oldState) => ({
						older: oldState?.older || [],
						newer: [...mergeNotificationsAndInvitations([], [payload.new]), ...(oldState?.newer || [])],
					}));
				},
			)
			.subscribe();
		return () => {
			supabase.removeChannel(changes);
		};
	}, [user]);

	const notificationsResponse = useGetGroupedNotifications();

	useEffect(() => {
		if (notificationsResponse?.data) {
			// obcas mne prijde ze tam zustanou vyset ty notifikace z realtime i po reset queries
			// ale mam pocit ze je to jen dev modem, chce to pak testnout nasazeny
			setNotifications(notificationsResponse?.data);
		}
	}, [notificationsResponse?.data]);

	const displayNotifications = useMemo(
		() => (notifications?.older?.length ?? 0) + (notifications?.newer?.length ?? 0) > 0,
		[notifications],
	);

	return (
		<PopoverRoot>
			<PopoverTrigger asChild>
				<Box as="div" position="relative">
					<Box as="div" w="fit-content" aria-label="open menu" mr={2} cursor="pointer">
						<FiBell size="18px" />
					</Box>
					{displayNotifications && (
						<Flex
							alignItems="center"
							justifyContent="center"
							position="absolute"
							bg="alertRed"
							borderRadius="50%"
							w="5"
							h="5"
							color="white"
							top="0"
							right="2"
						>
							<Text fontSize="12px" textStyle="xs">
								{(notifications?.older?.length ?? 0) + (notifications?.newer?.length ?? 0)}
							</Text>
						</Flex>
					)}
				</Box>
			</PopoverTrigger>
			<PopoverContent width="100%" maxW="400px" minW="300px">
				<PopoverTitle>{n('title')}</PopoverTitle>
				<PopoverBody gap="3" display="grid" w="100%" p={0} py={3} maxH="564px" overflowY="auto">
					{notifications && <Notifications groupedNotifications={notifications} />}

					{!((notifications?.older?.length ?? 0) + (notifications?.newer?.length ?? 0)) && (
						<Box w="100%" py={2}>
							<Text textAlign="center">{n('notFound')}</Text>
						</Box>
					)}
				</PopoverBody>
			</PopoverContent>
		</PopoverRoot>
	);
}
