import { GroupedNotificationData } from '@shared/zod-schemas/';

export const getTextColor = (type: string) => {
	if (type === 'warning') {
		return 'textOrange';
	}

	if (type === 'error') {
		return 'textRed';
	}

	return 'subTitleColor';
};

export const mergeNotificationsAndInvitations = (notifications: any[], invitations: any[]) => {
	const resultArray: GroupedNotificationData[] = [];
	notifications.forEach((notification) => {
		resultArray.push({
			id: notification.id,
			type: 'notification',
			createdAt: notification.createdAt,
			image: notification.user?.image || null,
			isRead: notification.isRead || false,
			senderName: notification.user?.name || null,
			noImageText: (notification.user?.name && notification.user?.name[0]) || null,
			notification: {
				content: notification.content,
				infoContent: notification.infoContent,
				type: notification.type,
			},
		});
	});
	invitations.forEach((invitation) => {
		const { owner } = invitation;
		resultArray.push({
			id: invitation.id,
			type: 'invitation',
			createdAt: invitation.createdAt,
			image: null,
			isRead: false,
			senderName: owner?.companyName || null,
			noImageText: (owner?.companyName && owner?.companyName[0]) || null,
		});
	});
	return resultArray;
};
