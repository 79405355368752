import { atom } from 'jotai';
import { Search } from '@shared/zod-schemas';

export const searchValueAtom = atom<string>('');
export const searchResultsAtom = atom<Search[] | undefined>();

export const resetSearchAtom = atom(null, (_get, set) => {
	set(searchValueAtom, '');
	set(searchResultsAtom, []);
});
