import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import { useTranslations } from 'next-intl';
import { useAtom } from 'jotai';
import { useGetSearchResults } from '@shared/client-api';

import CommandSkeleton from '@/components/command/CommandSkeleton';
import DebouncedInput from '@/components/DebouncedInput';
import SearchCategory from '@/components/command/search/SearchCategory';
import { resetSearchAtom, searchResultsAtom, searchValueAtom } from '@/components/command/store';
import { DialogBody, DialogCloseTrigger, DialogContent, DialogHeader, DialogRoot } from '@/components/ui/dialog';

export default function CommandMenu({
	isOpen,
	ownerId,
	onClose,
}: {
	isOpen: boolean;
	ownerId: string;
	onClose: () => void;
}) {
	const t = useTranslations('Command');

	const [searchValue, setSearchValue] = useAtom(searchValueAtom);
	const [searchResults, setSearchResults] = useAtom(searchResultsAtom);
	const [, resetSearch] = useAtom(resetSearchAtom);

	const fetchedSearchResults = useGetSearchResults(
		{
			q: searchValue,
			ownerId,
		},
		{
			query: { enabled: searchValue.length > 0 },
		},
	);

	React.useEffect(() => {
		setSearchResults(fetchedSearchResults.data);
	}, [fetchedSearchResults, setSearchResults]);

	return (
		isOpen && (
			<DialogRoot
				onExitComplete={() => {
					resetSearch();
				}}
				onOpenChange={() => onClose()}
				open={isOpen}
			>
				<DialogContent
					maxWidth="615px"
					maxHeight="69vh"
					overflowX="hidden"
					overflowY="auto"
					rounded="8px"
					border="1px"
					borderColor="borderMenu"
				>
					<DialogHeader roundedTop="8px" bg="bgMenu" py={0}>
						<Flex alignItems="center" gap={2} height="48px">
							<FiSearch size="18px" />
							<DebouncedInput
								value={searchValue}
								debounce={300}
								onChange={(value) => {
									setSearchValue(String(value));
								}}
								variant="flushed"
								placeholder={t('search')}
								fontSize="16px"
							/>

							<DialogCloseTrigger />
						</Flex>
					</DialogHeader>

					<DialogBody px={4} py={2}>
						<Flex flexDir="column">
							{!fetchedSearchResults.isLoading &&
								searchResults?.map((result) => (
									<SearchCategory key={result.id} result={result} onClose={onClose} />
								))}

							{!searchResults?.length && !fetchedSearchResults.isLoading && (
								<Text py={4} textAlign="center">
									{t('noResults')}
								</Text>
							)}

							{fetchedSearchResults.isLoading && <CommandSkeleton />}
						</Flex>
					</DialogBody>
				</DialogContent>
			</DialogRoot>
		)
	);
}
