import { Flex, Skeleton } from '@chakra-ui/react';

export default function CommandSkeleton() {
	return (
		<Flex flexDir="column" gap={1} justifyContent="flex-start" w="100%" py={2} px={4}>
			<Skeleton w="64px" h="18px" />
			<Skeleton w="100%" h="58px" />
		</Flex>
	);
}
