import { forwardRef } from 'react';
import type { ButtonProps as ChakraCloseButtonProps } from '@chakra-ui/react';
import { IconButton as ChakraIconButton } from '@chakra-ui/react';
import { LuX } from 'react-icons/lu';

export type CloseButtonProps = ChakraCloseButtonProps;

// eslint-disable-next-line react/display-name
export const CloseButton = forwardRef<HTMLButtonElement, CloseButtonProps>((props, ref) => (
	<ChakraIconButton variant="ghost" aria-label="Close" ref={ref} {...props}>
		{props.children ?? <LuX />}
	</ChakraIconButton>
));
